<template>
  <div class="skills-container">

    <table style="width: 100%">
      <tr>
        <th>Fachinfo Typ</th>
        <th>Fachinfo Wert</th>
      </tr>
      <tr v-for="fachinfo in clearingMessage.meldung_info.fach_infos">
        <td>{{fachinfo.typ}}</td>
        <td>{{fachinfo.wert}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  name: "eldaClearingDetails",
  data() {
    return {
      columns: 1,
    }
  },
  props: {
    clearingMessage: Object,
  },
  computed: {
    ...mapGetters(['']),

  },
  methods: {

  }
}
</script>

<style scoped>

.skills-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background: #ec9bb2;
  display: flex;
  flex-wrap: wrap
}


</style>
